import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { NotificationModal } from '../../../features/notification-modal/notification-modal.component';
import { AlertComponent } from '../../../shared/alert/alert.component';
import { ModalStateService } from '../../../shared/services/modal-state.service';
import { ActivityScheduleService } from '../../services/activity-schedule/activity-schedule.service';
import { ModalConfig } from '../../services/models/modal-config';
import { ProjectService } from '../../services/project/project.service';
import { UserService } from '../../services/user/user.service';
import { SwitchProjectDialogComponent } from '../switch-project-dialog/switch-project-dialog.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { datadogRum } from '@datadog/browser-rum';
datadogRum.init({
  applicationId: '73b05a7e-764f-41dc-9459-255c7cfec9bb',
  clientToken: 'pub7b1278eaafa62353aeba7333db6e6982',
  site: 'datadoghq.com',
  service: 'sipm-analytics',
  env: 'dev',

  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public pushRightClass: string;
  currentUser: any;
  userImage: any;
  storedData: any;
  intials = '';
  isPreference = false;
  projectName = '';
  milestoneArray: any = [];
  hide = false;
  taskResData: any = [];
  projectData: any = [];
  modalFlag: boolean = false;
  constructor(
    public router: Router,
    private readonly modalService: NgbModal,
    private readonly _projectSrv: ProjectService,
    private readonly authService: MsalService,
    private readonly _userSrv: UserService,
    private readonly _modalState: ModalStateService,
    private readonly _activityScheduleService: ActivityScheduleService
  ) {
    this.currentUser = this.authService.instance.getAllAccounts()[0];
    const name = this.currentUser.name.split(' ');
    this.intials = name[0].charAt(0) + name[1].charAt(0);
    datadogRum.setUser({
      id: this.currentUser.idTokenClaims,
      name: this.currentUser.name,
      email: this.currentUser.name,
      role: this.currentUser.idTokenClaims.roles[0],
    });
    this.router.events.subscribe((val) => {
      if (
        val instanceof NavigationEnd &&
        window.innerWidth <= 1024 &&
        this.isToggled()
      ) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit() {
    this._activityScheduleService.majorTaskData.subscribe((data) => {
      this.milestoneArray = data;
    });
    this.getNotificationData();
    this.pushRightClass = 'open';
    const imageReq = {
      emailList: [this.currentUser.username],
    };
    this.getAllProjects();
    this._projectSrv.projList.subscribe((result) => {
      if (result) {
        this.getAllProjects();
      }
    });
    this._projectSrv.prjDetails.subscribe((response) => {
      if (response.showFlag) {
        this.assignPreference(response.result);
      }
    });
    this._projectSrv.switchCreate.subscribe((val) => {
      if (val === true) {
        this.modalFlag = true;
        this.getAllProjects();
      }
    });
    this.userImage = sessionStorage.getItem('profilImage');
    setTimeout(() => {
      if (
        this.userImage === null ||
        this.userImage === undefined ||
        this.userImage === ''
      ) {
        this._userSrv.getImage(imageReq).subscribe((res) => {
          if (res && res[0] && res[0].imageInBase64) {
            const base64Data = res[0].imageInBase64;
            this.userImage = `data:image/jpeg;base64,${base64Data}`;
            sessionStorage.setItem('profilImage', this.userImage);
          }
        });
      }
      this.projectName = sessionStorage.getItem('currentPrjName');
      if (
        this.projectName === null ||
        this.projectName === undefined ||
        this.projectName === ''
      ) {
        this._userSrv.getPreference().subscribe((res) => {
          if (
            res.status.messages[0].code === 'SIPM_USER_ERR_005' ||
            res.status.messages[0].code === 'SIPM_PRO_ERR_067'
          ) {
            this.isPreference = false;
            this.openSwitchModal();
          } else {
            this.isPreference = true;
            this._userSrv.setCurrentPrj({
              projectName: res.data.selectedProject.projectName,
              projectCode: res.data.selectedProject.projectCode,
              from: 'default',
            });
            this.projectName = `${res.data.selectedProject.projectName} (${res.data.selectedProject.wbsProjectCode})`;
            sessionStorage.setItem(
              'currentPrj',
              res.data.selectedProject.projectCode
            );
            sessionStorage.setItem(
              'currentwbsPrjCode',
              res.data.selectedProject.wbsProjectCode
            );
            sessionStorage.setItem('currentPrjName', this.projectName);
          }
        });
      } else {
        this.isPreference = true;
      }
    }, 1000);
  }

  getAllProjects() {
    this._projectSrv.getProjectData().subscribe((res) => {
      if (res && res.status.code === '200') {
        this.storedData = res.data;
        if (this.modalFlag) {
          this.openSwitchModal();
        }
      }
    });
  }
  showNotification() {
    this.hide = true;
    if (this.milestoneArray && this.milestoneArray.length > 0) {
      this.modalService.open(NotificationModal, {
        size: 'md',
        centered: false,
        backdrop: 'static',
      });
    }
  }
  getNotificationData() {
    this.projectData = JSON.parse(sessionStorage.getItem('parentProject'));
    if (this.projectData) {
      const childProjects = [];
      this.projectData.ChildProject.forEach((x) => {
        x.majorTasks.forEach((y) => {
          y.childProjectCode = x.projectCode;
          childProjects.push(y);
        });
      });
      const childResData = [];
      childProjects.forEach((x) => {
        x.tasks.forEach((y) => {
          y.projectType = 'Child';
          childResData.push(y);
        });
      });
      const parentResData = [];
      this.projectData.ParentProject.majorTasks.forEach((x) => {
        x.tasks.forEach((y) => {
          y.projectType = 'Parent';
          parentResData.push(y);
        });
      });
      const mileStoneData = [...childResData, ...parentResData];
      mileStoneData.sort((a, b) =>
        new Date(a['startDate'])
          .toString()
          .localeCompare(new Date(b['startDate']).toString())
      );
      mileStoneData.forEach((x) => {
        if (
          x.type === 'Milestone' &&
          new Date() <= new Date(x.startDate) &&
          this.taskResData.length < 3
        ) {
          this.taskResData.push(x);
        }
      });
      this.milestoneArray = this.taskResData;
    }
  }
  openSwitchModal() {
    if (this.storedData && this.storedData.length > 0) {
      const modalRef = this.modalService.open(SwitchProjectDialogComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.storedData = this.storedData;
      modalRef.componentInstance.isPreference = this.isPreference;
      modalRef.componentInstance.selectedRowIndex = this.getSelectedProject();

      modalRef.result.then((result) => {
        if (result !== 'Modal Closed') {
          this.assignPreference(result);
        }
      });
    } else {
      if (
        this.currentUser.idTokenClaims.roles[0] === 'SIPM-ToyotaUser' ||
        this.currentUser.idTokenClaims.roles[0] === 'SIPM-ExternalUser'
      ) {
        const modalRef = this.modalService.open(AlertComponent, {
          centered: true,
          backdrop: 'static',
        });
        const config = new ModalConfig();
        config.modalHeader = '';
        config.modalBody =
          'Sorry ! No project assigned yet. Please contact your Project Lead.';
        config.cancelBtn = false;
        config.cancelBtnName = 'No';
        config.saveBtn = true;
        config.saveBtnName = 'Logout';
        config.deleteBtn = false;
        this._modalState.changeConfig(config);
        modalRef.result.then((result) => {
          if (result) {
            this.logout();
          } else {
            modalRef.close();
          }
        });
      } else {
        this._userSrv.setCurrentPrj({
          projectName: '',
          projectCode: '',
          from: 'no',
        });
      }
    }
  }
  assignPreference(result: any) {
    this._userSrv.setCurrentPrj({
      projectName: result.projectName,
      projectCode: result.projectCode,
      from: 'yes',
    });
    this.isPreference = true;
    this.projectName = `${result.projectName} (${result.wbsProjectCode})`;
    sessionStorage.setItem('currentPrj', result.projectCode);
    sessionStorage.setItem('currentwbsPrjCode', result.wbsProjectCode);
    sessionStorage.setItem('currentPrjName', this.projectName);
    const updatePreference = {
      selectedProject: {
        projectName: result.projectName,
        projectCode: result.projectCode,
        wbsProjectCode: result.wbsProjectCode,
      },
    };
    this._projectSrv.setSwitchProject({ showFlag: true });
    this.router.navigateByUrl('/dashboard');
    this._userSrv.updatePreference(updatePreference).subscribe();
  }
  getSelectedProject() {
    if (
      this.projectName !== null &&
      this.projectName !== undefined &&
      this.projectName !== ''
    ) {
      const select = this.storedData.filter((prj) => {
        if (prj.projectName === this.projectName.split(' (')[0]) {
          return {
            projectName: prj.projectName,
            projectCode: prj.projectCode,
          };
        } else {
          return '';
        }
      });
      if (select && select[0]) {
        return select[0];
      } else {
        return {
          projectName: '',
          projectCode: '',
        };
      }
    } else {
      return {
        projectName: '',
        projectCode: '',
      };
    }
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }
  exportPdf() {
    window.scroll(0, 0);
    document.getElementById('export').click();
    //document.getElementById('partnership').classList.toggle('part');
    document.getElementById('chart').classList.toggle('part');
    //document.getElementById('partnershipTable').classList.toggle('part');
    // document.getElementById('scope').classList.toggle('scope');
    const dept = document.getElementById('title').innerText;

    const data = document.getElementById('page-container');

    html2canvas(data).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      const doc = new jspdf('p', 'mm', 'a4');

      var position = 10;

      doc.text(dept, imgWidth / 2, 7, { align: 'center' });
      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          contentDataURL,
          'PNG',
          0,
          position + 10,
          imgWidth,
          imgHeight
        );
        heightLeft -= pageHeight;
      }
      doc.save(`${dept}.pdf`);
      // document.getElementById('partnership').classList.toggle('part');

      document.getElementById('chart').classList.toggle('part');

      //document.getElementById('partnershipTable').classList.toggle('part');
      //document.getElementById('scope').classList.toggle('scope');
    });
  }
  imageUpload() {
    const modalRef = this.modalService.open(ImageUploadComponent, {
      centered: true,
      backdrop: 'static',
    });

    const config = new ModalConfig();
    if (this.userImage) {
      config.modalBody = this.userImage;
    } else {
      config.modalBody = this.intials;
    }

    config.cancelBtn = false;
    config.modalHeader = 'Alert';
    config.saveBtn = true;
    config.cancelBtnName = 'No';

    config.saveBtnName = 'OK';
    config.deleteBtn = false;
    this._modalState.changeConfig(config);
    modalRef.result.then((result) => {
      if (result) {
        this.ngOnInit();
      }
      modalRef.close();
    });
  }
  logout() {
    sessionStorage.clear();
    this.authService.logoutRedirect();
  }
}
